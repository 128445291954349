import React, { useMemo } from 'react';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { ignoreRedirect } from 'core/services/redirectService';
import { FeatureTracked } from 'models/tracking';
import { useServiceTrackingContext } from '../tracking/serviceTrackingContext';
import { configuration } from 'config/constants';
import { DefaultTooltip } from '../tooltip/Tooltip';
import { uniqueId } from 'lodash-es';
import { useTranslation } from 'react-i18next';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgmarkets-request-access': any;
            }
        }
    }
}

type RequestAccessProps = {
    serviceKey: string;
    name?: Nullable<string>;
    url: string;
    popsIdentifier?: Nullable<string>;
    buttonClass?: string;
    useIcon?: boolean;
};

export const RequestAccess: React.FunctionComponent<RequestAccessProps> = ({
    serviceKey,
    name,
    url,
    popsIdentifier,
    buttonClass,
    useIcon,
}) => {
    const { t: translate } = useTranslation('tooltip');
    const { trackService } = useServiceTracking();
    const { serviceTracked } = useServiceTrackingContext();
    const { sharedCdnUrl } = configuration;
    const siteConfig = JSON.stringify({
        url,
        serviceKey,
        popsIdentifier,
        name,
    });

    const buttonId = useMemo(
        () => (useIcon ? uniqueId('requestaccessTarget') : ''),
        [useIcon],
    );

    const onRequestAccess = (event: React.MouseEvent) => {
        ignoreRedirect(event);
        trackService(FeatureTracked.OpenRequestAccess, serviceTracked);
    };

    // "?v=today" only to force widget refresh
    const today = new Date().toLocaleDateString();
    const requestAccessJs = `${sharedCdnUrl}/sgr-widgets/sgmarkets-request-access/sgmarkets-request-access.js?v=${today}`;

    return <div onClick={onRequestAccess} aria-hidden>
        <WidgetLazyLoader
            script={requestAccessJs}
        >
            <DefaultTooltip
                text={translate('tooltip:requestAccess')}
                enabled={useIcon}
            >
                <sgmarkets-request-access
                    url={url}
                    onClick={(e: Event) => e.preventDefault()}
                    site={siteConfig}
                    classBtn={`btn btn-lg ${buttonClass || 'btn-flat-socgen'}`}
                    use-icon={useIcon ?? false}
                    id={buttonId}
                    sg-bootstrap="5"
                />
            </DefaultTooltip>
        </WidgetLazyLoader>
    </div>;
};
