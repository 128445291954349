import React, { FC, useEffect } from 'react';
import { AccessType } from 'models/accessType';
import { useLocation, useNavigate } from 'react-router-dom';
import { configuration } from 'config/constants';
import { useBoardServices } from 'components/hooks/services/useBoardServices';
import { LoadingPage } from 'components/common/loading/LoadingPage';

const insightPublicRedirect: Record<string, string> = {
    '#equity/compliance': 'compliance/equity',
    '#credit/compliance': 'compliance/credit',
};

export const RedirectComponent: FC = () => {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const { insightPublicUrl } = configuration;
    const services = useBoardServices(AccessType.All);
    const servicesAreLoaded = services?.services?.length > 0;

    // Gets whether the current url is an Insight Public url or not.
    const insightPublicHash = insightPublicRedirect[hash?.toLowerCase()];
    if (insightPublicHash) {
        window.location.href = `${insightPublicUrl}/${insightPublicHash}`;
        return <LoadingPage />;
    }

    useEffect(() => {
        // If user have only one service, we should redirect to this service
        if (servicesAreLoaded) {
            const servicesWithExplicitAccess = services?.services?.filter(x => x.HasAccess && !x.HasDefaultAccessAll);
            if (servicesWithExplicitAccess?.length === 1 && servicesWithExplicitAccess[0].Url) {
                window.location.href = servicesWithExplicitAccess[0].Url;
            } else {
                navigate('/myservices');
            }
        }
    }, [servicesAreLoaded]);

    return <LoadingPage />;
};
