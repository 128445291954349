export const getSessionItem = <T>(key: string): T | null => {
    const data = window.sessionStorage.getItem(key) ;
    if (data) {
        try {
            return JSON.parse(data) as T;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {
            // Swallow
        }
    }

    return null;
};

export const setSessionItem = <T>(key: string, value: T): void => {    
    window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeSessionItem = (key: string): void => {
    window.sessionStorage.removeItem(key);
};