import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { configuration } from 'config/constants';
import { useTranslation } from 'react-i18next';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { GetPersonalInformation } from 'core/services/myAccount/myAccountService';
import { useMyAccountPersonalInformation } from 'components/hooks/myAccount/useMyAccountPersonalInformation';
import classNames from 'classnames';
import { ContentChecked, LabeledContentChecked } from './ContentChecked';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from 'components/common/SkeletonWrapper';
import { openHelpCenterTopic } from 'components/layout/HelpCenter';

import './AccountPersonalInformation.scss';

export const AccountPersonalInformation: FC = () => {
    const mediaBreakpoint = useMediaBreakpoint();
    const { isLoading, personalInformation } = useMyAccountPersonalInformation();
    const { isSharinboxVisible, isSgGroupCurrentUser } = useApplicationContext();
    const dispatch = useDispatch();
    const { logoutUrl, personalInformationHelpSgGroup, personalInformationHelpClient } = configuration;
    const { t: translate } = useTranslation('my-account');

    const topicId = isSgGroupCurrentUser ? personalInformationHelpSgGroup : personalInformationHelpClient;

    const hasBirthOrTaxInformation = personalInformation?.birthDate
        || personalInformation?.birthCity
        || personalInformation?.birthZipcode
        || personalInformation?.birthCountry
        || personalInformation?.taxInformations;

    const hasAddress = personalInformation?.addressLine1
        || personalInformation?.addressLine2
        || personalInformation?.addressCity
        || personalInformation?.addressCity
        || personalInformation?.addressZipcode
        || personalInformation?.addressCountry;


    const initialsClassNames = () => {
        let classes = 'd-flex align-items-center justify-content-center text-secondary fw-semibold';
        if (['lg', 'xl', 'xxl'].includes(mediaBreakpoint)) {
            classes += ' circle-text ';
        } else {
            classes += ' circle-text-mini ';
        }
        return classNames(classes);
    };

    const mainContentClassNames = () => {
        let classes = 'h-100 d-lg-flex justify-content-lg-center px-4 px-lg-5';
        if (['lg', 'xl', 'xxl'].includes(mediaBreakpoint)) {
            classes += ' bg-lvl2';
        }
        return classNames(classes);
    };

    const birthOrTaxInformationWrapperClassNames = () => {
        let classes = '';
        if (['md'].includes(mediaBreakpoint)) {
            classes += ' w-50 pe-4';
        } else {
            classes += ' border-top';
        }
        return classNames(classes);
    };

    const birthOrTaxInformationClassNames = () => {
        let classes = 'pt-4';
        if (['md'].includes(mediaBreakpoint)) {
            classes += ' border-top';
        } else if (['sm'].includes(mediaBreakpoint)) {
            classes += ' w-50';
        }
        return classNames(classes);
    };

    const HowToUpdate: FC = () => {
        return <>
            {!isSharinboxVisible ? <div className="mb-2">
                <button className="btn btn-flat-primary btn-lg btn-icon-text" onClick={() => openHelpCenterTopic(topicId)}>
                    <i className="icon">help_outline</i>
                    <span className="ms-2" >{translate('personal-information-how-to-update')}</span>
                </button>
            </div> : null
            }
        </>;
    };

    useEffect(() => {
        dispatch<any>(GetPersonalInformation());
    }, [dispatch]);

    return <div className={mainContentClassNames()}>
        {isLoading
            ? <div className="d-flex flex-grow-1 flex-column pt-4 px-5">
                <AccountPersonalInformationSkeleton />
            </div>
            : <div className="mt-5">
                <div className="d-flex flex-lg-column flex-column-md-row mb-4">
                    <div
                        className={initialsClassNames()}>
                        <span>{(personalInformation?.firstName?.charAt(0) || '') + (personalInformation?.lastName?.charAt(0) || '')}</span>
                    </div>
                    <div className="ms-4 ms-lg-0">
                        <div className="h2 mt-md-0 mt-lg-4 text-capitalize">
                            {personalInformation?.civility} {personalInformation?.fullName}
                        </div>
                        <ContentChecked content={personalInformation?.company} />
                        <span className="text-secondary">
                            <ContentChecked content={personalInformation?.jobTitle} />
                        </span>
                    </div>
                    <div className="ms-auto d-none d-md-block d-lg-none">
                        <HowToUpdate />
                    </div>
                </div>

                <div className="d-flex flex-column flex-md-row flex-lg-column">
                    <div className="py-4 border-top flex-md-fill">
                        <ContentChecked content={personalInformation?.email} />
                        <ContentChecked content={personalInformation?.phoneNumber} />
                    </div>
                    {hasAddress ? <div className="pt-4 border-top flex-md-fill ms-md-4 ms-lg-0">
                        <span className="text-secondary">{translate('personal-information-address')}</span>
                        <ContentChecked content={personalInformation?.addressLine1} />
                        <ContentChecked content={personalInformation?.addressLine2} />
                        <ContentChecked content={personalInformation?.addressCity} />
                        <ContentChecked content={personalInformation?.addressZipcode} />
                        <ContentChecked content={personalInformation?.addressCountry} />
                    </div> : null
                    }
                </div>

                {hasBirthOrTaxInformation ? <div className={birthOrTaxInformationWrapperClassNames()}>
                    <div className={birthOrTaxInformationClassNames()}>
                        <LabeledContentChecked content={personalInformation?.birthDate} translationKey="personal-information-birth-date" />
                        <LabeledContentChecked content={personalInformation?.birthCity} translationKey="personal-information-birth-city" />
                        <LabeledContentChecked content={personalInformation?.birthZipcode} translationKey="personal-information-birth-zipcode" />
                        <LabeledContentChecked content={personalInformation?.birthCountry} translationKey="personal-information-birth-country" />
                        <LabeledContentChecked content={personalInformation?.birthCitizenship} translationKey="personal-information-citizenship" />

                        {personalInformation?.taxInformations ? <>
                            {personalInformation?.taxInformations.map(ti => <div key={ti.taxId}>
                                <LabeledContentChecked content={ti.taxId} translationKey="personal-information-tax-id" />
                                <LabeledContentChecked content={ti.taxResidence} translationKey="personal-information-tax-residence" />
                            </div>,
                            )}
                        </> : null
                        }
                    </div>
                </div> : null
                }

                <div className="mt-5">
                    <div className="d-block d-md-none d-lg-block">
                        <HowToUpdate />
                    </div>
                    <a href={logoutUrl} className="d-none d-lg-block">
                        <button type="button" className="btn btn-lg btn-default w-100">{translate('personal-information-sign-out')}</button>
                    </a>
                </div>
            </div>
        }
    </div>;
};

const AccountPersonalInformationSkeleton = () => {
    return (
        <SkeletonWrapper>
            <Skeleton width={128} height={128} className="mb-3" circle={true} />
            <Skeleton height={50} className="mb-4" />
            <Skeleton width={180} height={50} className="mb-4" />
            <Skeleton height={76} className="mb-4" />
            <Skeleton height={76} className="mb-4" />
        </SkeletonWrapper>
    );
};