import React, { BaseSyntheticEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSearchKey, searchNotifications } from 'store/Notification/notificationThunk';
import { AppState } from 'store/store';
import { useNotificationListFilter } from 'components/hooks/Notification/useNotificationListFilter';
import { SkeletonWrapper } from 'components/common/SkeletonWrapper';
import Skeleton from 'react-loading-skeleton';
import { NotificationFilter } from './Filter/NotificationFilter';
import { NotificationItem } from './NotificationItem';
import { useNotificationTitle } from 'components/hooks/Notification/useNotificationTitle';
import { debounce } from 'lodash-es';
import { SEARCH_DEBOUNCE_IN_MS } from 'config/constants';
import { Blinker } from 'components/common/loading/Loading';
import { Footer } from 'components/layout/Footer';
import { NotificationsSearchState } from 'store/Notification/notificationReducer';

import './Notifications.scss';

const handleInfinityScrollDebounced = debounce((e: React.BaseSyntheticEvent, action: () => void) => {
    const scrollApproxMargin = 150;
    if (e.target.scrollHeight - Math.ceil(e.target.scrollTop) > e.target.clientHeight + scrollApproxMargin) {
        return;
    }
    action();
}, SEARCH_DEBOUNCE_IN_MS);

const searchResultSelector = (state: AppState, searchKey: string): NotificationsSearchState => {
    if (state.notification.searchResult.searchKey === searchKey) {
        return state.notification.searchResult;
    }

    return {
        state: 'neverFetched',
        lastFetchedPage: 0,
        isLastPage: false,
        searchKey,
    };
};

export const Notifications: FC = () => {
    const dispatch = useDispatch();
    const { title } = useNotificationTitle();
    const { producerCodeActive, readStatusActive, isAllProducerActive } = useNotificationListFilter();

    const searchProducerCode = isAllProducerActive ? undefined : producerCodeActive;
    const searchKey = getSearchKey(searchProducerCode, readStatusActive);

    const { state, data, lastFetchedPage } = useSelector((state: AppState) => searchResultSelector(state, searchKey), shallowEqual);

    const isFetching = state === 'isFetching';
    const showSkeleton = state === 'neverFetched' || (isFetching && lastFetchedPage === 0);

    const handleInfinityScroll = (e: BaseSyntheticEvent) => {
        handleInfinityScrollDebounced(e, () => fetchNotifications(true));
    };

    const fetchNotifications = (fetchNextPage: boolean) => {
        dispatch<any>(searchNotifications(searchProducerCode, readStatusActive, fetchNextPage));
    };

    useEffect(() => {
        fetchNotifications(false);
    }, [producerCodeActive, readStatusActive, state === 'outdated']);

    return (
        <div className="d-flex flex-column flex-grow-1 overflow-y-auto notifications-container" onScroll={handleInfinityScroll}>
            <div className="d-flex flex-grow-1 w-100">
                {/* Usefull to center notifications from the center of the page (NOT centedred from parent)*/}
                <div className="flex-grow-1" />
                <div
                    className="d-flex flex-column mx-0 mx-md-3 mx-lg-4 flex-grow-1 w-100 mb-32px"
                    style={{ maxWidth: 1080 }}
                >
                    <div className="d-flex justify-content-md-between align-md-items-center mt-2 mt-md-4 mb-0 mb-md-3 notifications-filter-wrapper">
                        <h2 className="d-none d-md-block text-truncate m-0 me-2">
                            {title}
                        </h2>
                        <div className="d-flex flex-grow-1 justify-content-between">
                            <NotificationFilter />
                        </div>
                    </div>
                    {showSkeleton && <NotificationsSkeleton />}
                    {!showSkeleton && <>
                        <section className="list-group notifications-list">
                            {data?.length
                                ? data.map(notif => <NotificationItem notif={notif} key={notif.id} />)
                                : <EmptyNotification />}
                        </section>
                        {isFetching && <div className="d-flex justify-content-end">
                            <Blinker className="blinker-lg" />
                        </div>}
                    </>}
                </div>
                {/* Usefull to center notifications from the center of the page (NOT centedred from parent)*/}
                <div className="flex-grow-1" />
                <div className="fake-miror-menu" />
            </div>
            <Footer />
        </div>
    );
};

const NotificationsSkeleton: FC = () => <SkeletonWrapper>
    <Skeleton height={60} className="mb-1" count={10} />
</SkeletonWrapper>;

const EmptyNotification: FC = () => {
    const { t: translate } = useTranslation('my-notification');
    return (
        <section className="pt-48px h-100 d-flex justify-content-center">
            <article className="empty-state mw-100">
                <i className="icon icon-lg">check_circle_outline</i>
                <h4>{translate('noNotification')}</h4>
            </article>
        </section>
    );
};
