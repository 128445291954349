import React, { FC, useEffect } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import { DisclaimerLastValidated } from './DisclaimerLastValidated';
import { DisclaimerPage } from './DisclaimerPage';
import { ServiceboardHeader } from 'components/layout/header/ServiceboardHeader';
import { LanguageEnFrOnly } from 'components/hooks/LanguageProvider';
import { COUNTRY_RESTRICTED_BROWSE_PATH } from 'models/constants';
import { Footer } from 'components/layout/Footer';
import { CountryRestrictedPage } from 'components/pages/disclaimerPage/CountryRestrictedPage';
import NotFound from '../errors/NotFound';

import './DisclaimerLayout.scss';

export const DisclaimerLayout: FC = () => {
    useEffect(() => {
        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    return (
        <>
            <LanguageEnFrOnly />
            <ServiceboardHeader minimalHeader={true} />
            <Switch>
                <Route
                    path="/page/:application"
                    element={<DisclaimerPage />}
                />
                <Route
                    path="/file"
                    element={<DisclaimerLastValidated />}
                />
                <Route
                    path={COUNTRY_RESTRICTED_BROWSE_PATH}
                    element={<CountryRestrictedPage />}
                />
                <Route path="*" element={<NotFound />} />
            </Switch>
            <Footer />
        </>
    );
};
