import React from 'react';
import { configuration } from 'config/constants';
import { SettingListGroup, SettingListGroupItemProps } from '../components/SettingListGroup';
import { ActionLinkButton } from '../components/ActionLinkButton';
import { useMyAccountSetting } from 'components/hooks/myAccount/useMyAccountSetting';
import { useTranslation } from 'react-i18next';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';

export const SecuritySettings = () => {
    const { t: translate } = useTranslation('my-account');
    const { changePasswordUrl, manage3SKeyUrl, resetSecondFactorUrl, setupSecondFactorUrl } = configuration;
    const { is3SKeyVisible } = useApplicationContext();
    const { setting } = useMyAccountSetting();
    if (!setting) {
        return <></>;
    }

    const items: SettingListGroupItemProps[] = [
        {
            titleLngKey: 'title-password',
            descriptionLngKey: 'settings-security-password-desc',
            iconName: 'lock',
            wrapInLink: true,
            itemUrl: changePasswordUrl,
            actionComponent: <ActionLinkButton labelLngKey="label-change" actionUrl={changePasswordUrl} />,
        },
    ];

    const setAuthVerifiedSuccessIcon = (item: SettingListGroupItemProps) => {
        item.iconName = 'verified_user';
        item.iconColor = 'text-success';
    };

    const setAuthSecurityDangerIcon = (item: SettingListGroupItemProps) => {
        item.iconName = 'security';
        item.iconColor = 'text-danger';
    };

    const AddAuth2FaSettingListGroupItemProps = (items: SettingListGroupItemProps[],
        description: string,
        actionLabelLngKey: string,
        actionUrl: string,
        setIcon: (item: SettingListGroupItemProps) => void): void => {
        const item: SettingListGroupItemProps = {
            titleLngKey: 'settings-security-google-auth-title',
            description: description,
            descriptionLngKey: '',
            iconName: '',
            wrapInLink: !!actionUrl,
            itemUrl: actionUrl,
            actionComponent: <ActionLinkButton labelLngKey={actionLabelLngKey} actionUrl={actionUrl} />,
        };
        setIcon(item);
        items.push(item);
    };

    if (setting.has2Factor && setting.canReset2FA) {
        AddAuth2FaSettingListGroupItemProps(items,
            translate('settings-security-google-auth-desc-internal'),
            'label-reset',
            resetSecondFactorUrl,
            setAuthVerifiedSuccessIcon);
    }
    else if (setting.has2Factor && !setting.canReset2FA) {
        AddAuth2FaSettingListGroupItemProps(items,
            translate('settings-security-google-auth-desc-external'),
            '',
            '',
            setAuthVerifiedSuccessIcon);
    }
    else if (!setting.has2Factor && setting.hasPeriod2FactorActive) {
        AddAuth2FaSettingListGroupItemProps(items,
            translate('settings-security-google-auth-desc-onboarding',
                {
                    nbDays: setting.expiredTime2FactorInDays,
                }),
            'label-activate',
            setupSecondFactorUrl,
            setAuthSecurityDangerIcon);
    }
    else if (!setting.has2Factor && !setting.hasPeriod2FactorActive && setting.canReset2FA) {
        AddAuth2FaSettingListGroupItemProps(items,
            translate('settings-security-google-auth-desc-2-fa-required'),
            'label-reset',
            resetSecondFactorUrl,
            setAuthSecurityDangerIcon);
    }

    if (is3SKeyVisible) {
        const item3skey: SettingListGroupItemProps = {
            titleLngKey: 'settings-security-manage-3skey-title',
            descriptionLngKey: 'settings-security-manage-3skey-desc',
            iconName: 'security',
            wrapInLink: true,
            itemUrl: manage3SKeyUrl,
            actionComponent: <ActionLinkButton labelLngKey="label-manage" actionUrl={manage3SKeyUrl} />,
        };

        items.push(item3skey);
    }

    return <SettingListGroup items={items} titleLngKey="settings-security" />;
};