import { AccountCenter } from 'components/layout/AccountCenter';
import { Collapse } from 'reactstrap';
import { SearchBar } from 'components/pages/myServicesPage/components/search/SearchBar';
import { ServiceboardHeaderMenuNavbar } from './ServiceboardHeaderMenuNavbar';
import { ServiceboardHeaderNavbar } from 'models/navigation';
import { SingleServiceModal } from 'components/pages/serviceDescription/SingleServiceModal';
import { useLocation } from 'react-router-dom';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import classNames from 'classnames';
import React, { useState } from 'react';
import sgmWordmark from 'static/images/sgm_wordmark.svg';
import socgenLogoCompactUrl from 'static/images/socgen_logo_compact.svg';

type ServiceboardHeaderProps = {
    minimalHeader?: boolean
};

const isIE = (() => {
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11

    return msie > 0 || trident > 0;
})();

export const ServiceboardHeader: React.FC<ServiceboardHeaderProps> = ({ minimalHeader = false }) => {
    const mediaBreakpoint = useMediaBreakpoint();
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const { pathname } = useLocation();

    const navbarClassName = {
        'navbar-sm': ['xs', 'sm'].includes(mediaBreakpoint),
        'navbar-md': ['md', 'lg', 'xl'].includes(mediaBreakpoint),
        'navbar-lg': ['xxl'].includes(mediaBreakpoint),
    };

    const navbarHeaderClassName = {
        'overflow-hidden': ['xs', 'sm'].includes(mediaBreakpoint),
    };

    const toggleSearch = () => {
        const toggleSearchBar = !openSearchBar;
        setOpenSearchBar(toggleSearchBar);
        setDropdownMenuOpen(false);
    };

    const toggleDropdownMenu = () => {
        const toggleDropdownMenu = !dropdownMenuOpen;
        setDropdownMenuOpen(toggleDropdownMenu);
        setOpenSearchBar(false);
    };

    const menuActive: ServiceboardHeaderNavbar = pathname?.startsWith('/catalog') ? 'catalog' : 'myservices';

    return <>
        <nav className={classNames('navbar bg-lvl1 p-0', navbarClassName)}>
            <div className={classNames('navbar-title', navbarHeaderClassName)}>
                <a href="/" className="navbar-title-link">
                    <div className="navbar-logo">
                        <img src={socgenLogoCompactUrl} alt="SG logo" />
                    </div>
                    <div className="navbar-title-divider" />
                    <div className="navbar-service-name">
                        <img src={sgmWordmark} alt="SG logo" />
                    </div>
                </a>
                {<div className="d-md-none d-flex align-items-center">
                    <button
                        className="navbar-header-align btn btn-flat btn-xl btn-icon flex-center"
                        onClick={toggleDropdownMenu}>
                        <span className="icon">menu</span>
                    </button>
                </div>}
            </div>

            {!minimalHeader && <div className="d-none d-xl-flex container-xl">
                <div className="row w-100 align-items-center">
                    <div className="col-9">
                        <SearchBar />
                    </div>
                    <div className="col-3">
                        <ServiceboardHeaderMenuNavbar active={menuActive} />
                    </div>
                </div>
            </div>}

            <div className="navbar-toolbar">
                <button
                    className={classNames('d-flex d-xl-none btn btn-flat-primary p-2 me-n1', navbarHeaderClassName)}
                    onClick={toggleSearch}>
                    <span className="icon icon-md">search</span>
                </button>
                <AccountCenter />
            </div>
        </nav >

        <Collapse isOpen={dropdownMenuOpen} className="w-100 bg-lvl2 text-secondary navbar">
            <ServiceboardHeaderMenuNavbar active={menuActive} />
        </Collapse>
        <div
            id="navbar-searchbar"
            className={classNames('bg-lvl2 d-xl-none w-100 p-2 collapse d-flex justify-content-center', { 'd-none': !openSearchBar })}>
            <SearchBar />
        </div>
        <SingleServiceModal />
        {isIE && <div style={{ height: '110px' }} />}
    </>;
};
