import { Dispatch } from 'react';
import { ActionType } from 'store/actions/ActionType';
import {
    createGenerateUserDisclaimerFetchRequestAction,
    createGenerateUserDisclaimerFetchSuccessAction,
    createGenerateUserDisclaimerFetchFailureAction,
    createLastValidatedDisclaimerFetchRequestAction,
    createLastValidatedDisclaimerFetchSuccessAction,
    createLastValidatedDisclaimerFetchFailureAction,
} from 'store/actions/disclaimerAction';
import { ThunkResult } from 'store/store';
import { ITechnicalLogger } from '../common/logging/logger';
import { UserDisclaimersApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';
import { ValidateUserDisclaimerRequest } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export const generateDisclaimer = (language?: string, application?:string): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        _state,
        { logger },
    ) => {
        dispatch(createGenerateUserDisclaimerFetchRequestAction());
        try {
            const disclaimer = await UserDisclaimersApi.generateContent(language, application);
            dispatch(createGenerateUserDisclaimerFetchSuccessAction(disclaimer));
            return disclaimer;
        } catch (error: unknown) {
            logger.error(error);
            await tryIgnoreDisclaimer(logger);
            dispatch(createGenerateUserDisclaimerFetchFailureAction());
            return null;
        }
    };
};

export const getLastValidatedDisclaimer = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        _state,
        { logger },
    ) => {
        dispatch(createLastValidatedDisclaimerFetchRequestAction());
        try {
            const disclaimer = await UserDisclaimersApi.getLastValidatedContent();
            dispatch(createLastValidatedDisclaimerFetchSuccessAction(disclaimer));
            return disclaimer;
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createLastValidatedDisclaimerFetchFailureAction());
            return null;
        }
    };
};

export const validateDisclaimer = (
    application: string,
): ThunkResult => {
    return async (
        _dispatch: Dispatch<ActionType>,
        _state,
        { logger },
    ) => {
        try {
            const request = { application } as ValidateUserDisclaimerRequest;
            await UserDisclaimersApi.validateUserDisclaimer(request);
        } catch (error: unknown) {
            logger.error(error);
            await tryIgnoreDisclaimer(logger);
        }
    };
};

const tryIgnoreDisclaimer = async (logger: ITechnicalLogger): Promise<void> => {
    try {
        await UserDisclaimersApi.ignoreUserDisclaimer();
    } catch (error: unknown) {
        logger.error(error);
    }
};
