import React from 'react';
import { useLanguage } from 'components/hooks/LanguageProvider';
import { useNavigateAs } from 'components/hooks/navigation/UseNavigateAs';

import { useApplicationContext } from 'components/hooks/UseApplicationContext';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-account-center': any;
            }
        }
    }
}

export const AccountCenter: React.FC = () => {
    const { setPreferredLanguage, availableLanguages, websiteLanguage } = useLanguage();
    const { showNavigateAs, navigateAsUser, navigateAsUserContacts,
        changeNavigateAsUserCallback, stopNavigateAsCallback } = useNavigateAs();
    const { hasOnlyB2b2cAccess } = useApplicationContext();

    let accountCenterRef: HTMLElement | null = null;
    const setMountedElement = (mountedElement: HTMLElement | null) => {
        if (!accountCenterRef && mountedElement) {
            SetAccountCenterEventListeners(mountedElement);
        } else if (accountCenterRef && !mountedElement) {
            SetAccountCenterEventListeners(accountCenterRef);
        }

        accountCenterRef = mountedElement;
    };

    const SetAccountCenterEventListeners = (mountedElement: HTMLElement) => {
        mountedElement.addEventListener(
            'sgwt-account-center--language-changed',
            (evt: any) => { setPreferredLanguage(evt.detail.language); },
        );
        mountedElement.addEventListener(
            'sgwt-account-center--stop-navigation-as',
            stopNavigateAsCallback,
        );
        mountedElement.addEventListener(
            'sgwt-account-center--navigate-as-select-user',
            changeNavigateAsUserCallback,
        );
    };

    // To have the object expected by account-center web-component
    // `impersonateUser` and `userContacts` are string because otherwise the web-component cannot understand
    const impersonateUser = JSON.stringify(navigateAsUser);
    const userContacts = JSON.stringify(navigateAsUserContacts);
    const mode = hasOnlyB2b2cAccess ? 'b2b2c' : 'sg-markets';
    return <sgwt-account-center
        ref={setMountedElement}
        id="sgwtAccountCenter"
        language={websiteLanguage}
        available-languages={availableLanguages.join(',')}
        authentication="sg-connect-v2"
        mode={mode}
        navigate-as={showNavigateAs}
        navigate-as-user={impersonateUser}
        navigate-as-list={userContacts}
        producer-code="sgm_myservices"
    />;
};
