import React, { useEffect, useState, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    generateDisclaimer,
    validateDisclaimer,
} from 'core/services/disclaimer/disclaimerService';
import { configuration } from 'config/constants';
import {
    secureRedirectDomain,
    redirectExternalReplaceUrl,
} from 'core/services/redirectService';
import { AppState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components/common/loading/Loading';
import { DefaultTooltip } from 'components/common/tooltip/Tooltip';

const getDisclaimer = (app: AppState) => {
    const content = app.disclaimer.generatedContent;
    return {
        isLoading: content?.isLoading || false,
        hasError: content?.hasError || false,
        html: content?.data,
    };
};

export const DisclaimerPage: FC = () => {
    const config = configuration;
    const dispatch = useDispatch();
    const { application = '' } = useParams();
    const location = useLocation();
    const disclaimer = useSelector(getDisclaimer);
    const { t: translate, i18n: { language } } = useTranslation('disclaimer');
    const navigate = useNavigate();
    const [isRead, setIsRead] = useState(false);

    useEffect(() => {
        dispatch<any>(generateDisclaimer(language, application));
    }, [language, application]);

    const redirect = useCallback(() => {
        const searchParams = new URLSearchParams(location.search);
        const returnUrl = searchParams.get('ReturnUrl') || searchParams.get('returnUrl') || '';
        redirectExternalReplaceUrl(
            secureRedirectDomain(
                config.returnUrlDomainWhiteList,
                returnUrl,
            ),
        );
    }, [location.search, config.returnUrlDomainWhiteList]);

    useEffect(() => {
        if (disclaimer.hasError) {
            redirect();
        }
    }, [disclaimer.hasError, redirect]);

    const deny = () => {
        navigate('/');
    };

    const accept = async () => {
        await dispatch<any>(validateDisclaimer(application));
        redirect();
    };

    const handleDisclaimerScroll = (e: React.BaseSyntheticEvent) => {
        const scrollApproxMargin = 150;
        const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) <= e.target.clientHeight + scrollApproxMargin;
        if (bottom) {
            setIsRead(true);
        }
    };

    return (
        <div className="d-flex mx-3">
            <aside className="col-lg-3 d-none d-lg-flex">
                <div className="disclaimer-page-background" />
            </aside>
            <div className="d-flex flex-column col-lg-6 px-lg-5 disclaimer-page-column overflow-hidden">
                <h2 className="fw-bold">
                    {translate('disclaimer:title')}
                </h2>

                <p className="text-secondary fw-medium mb-3">
                    {translate('disclaimer:noticePart1')}
                </p>

                <p className="text-secondary fw-medium mb-3">
                    {translate('disclaimer:noticePart2')}
                </p>

                <div className="my-3 border-top d-flex flex-grow-1 overflow-auto" onScroll={handleDisclaimerScroll}>
                    {disclaimer.isLoading && <div
                        className="p-2 p-lg-4 pb-5 pb-lg-5">
                        <Loading />
                    </div>
                    }
                    {!disclaimer.isLoading && <div
                        className="p-2 p-lg-4 pb-5 pb-lg-5"
                        dangerouslySetInnerHTML={{
                            __html: disclaimer.html || '',
                        }}
                    />}
                </div>

                <div className="d-flex justify-content-between pb-4 flex-shrink-0">
                    <div className="flex-grow-1" />
                    <div className="d-flex">
                        <button
                            className="btn btn-flat-primary btn-lg me-2"
                            onClick={deny}
                        >
                            {translate('disclaimer:deny')}
                        </button>
                        <DefaultTooltip text={translate('disclaimer:pleaseReadDisclaimer')} enabled={!isRead}>
                            <span id="disclaimer-accept-button">
                                <button
                                    className="btn btn-primary btn-lg"
                                    onClick={accept}
                                    disabled={!isRead}
                                >
                                    {translate('disclaimer:accept')}
                                </button>
                            </span>
                        </DefaultTooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};
