import { searchItems, setSearchTerm } from 'store/SgmSearch/sgmSearchThunk';
import { redirectExternalOpenNewUrl } from 'core/services/redirectService';
import { debounce } from 'lodash-es';
import React, { createRef, useCallback, useRef, useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { Combobox } from './Combobox';
import { QuicksearchResults } from './QuicksearchResults';
import { TopicTracked, FeatureTracked } from 'models/tracking';
import { UseServiceTracking, useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { ActionTrackingInfoProvider } from 'components/common/tracking/serviceTrackingContext';
import { useEventListener } from 'components/hooks/useEventListener';
import { TRACKING_SEARCH_TERM_KEY } from 'models/constants';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { QuicksearchStartTyping } from './QuickSearchStartTyping';
import { useSearchQuery } from 'components/hooks/search/useSearchQuery';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { useSearchItemResults } from 'components/hooks/search/useSearchItemResults';
import classNames from 'classnames';

import './SearchBar.scss';

const ACCEPTED_KEYS = '0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';

interface SearchBarProps {
    className?: string;
}

const debouncedTrackAction = debounce((searchTerm: string, isSearchPage: boolean, trackAction: UseServiceTracking['trackAction']) => {
    const feature = isSearchPage ? FeatureTracked.OpenAdvancedSearch : FeatureTracked.OpenQuickSearch;
    const topic = isSearchPage ? TopicTracked.AdvancedSearch : TopicTracked.QuickSearch;

    trackAction(feature, {
        topic: topic,
        parameters: {
            [TRACKING_SEARCH_TERM_KEY]: searchTerm,
        },
    });
}, 2000);

export const SearchBar: FC<SearchBarProps> = ({ className }) => {
    const { t: translate, i18n: { language } } = useTranslation('service-list');
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { trackAction } = useServiceTracking();
    const { canSearchPersonsAndCompanies } = useApplicationContext();
    const mediaBreakpoint = useMediaBreakpoint();
    const searchMatch = useMatch('/search/:searchTerm/*');

    const isSearchPage = !!pathname.match(/^\/search\//i);
    const [showQuicksearch, setShowQuicksearch] = useState(false);
    const rootElement = useRef(null);
    const inputRef = createRef<HTMLInputElement>();

    const { selectedCategories, selectedSubcategories } = useSearchQuery();
    const { searchTerm } = useSearchItemResults();

    useEffect(() => {
        const initialSearchTerm = searchMatch?.params.searchTerm || '';
        if (initialSearchTerm) {
            dispatch<any>(setSearchTerm(initialSearchTerm));
        }
    }, []);

    useEffect(() => {
        dispatch<any>(searchItems(searchTerm, selectedCategories, selectedSubcategories, language));
    }, [searchTerm, selectedCategories.map(x => x.key).join(','), selectedSubcategories.map(x => x.key).join(','), language]);

    const keydownIntoSearch = useCallback((event: Event) => {
        const keyboardEvent = event as KeyboardEvent;
        const target = keyboardEvent.target as HTMLElement;
        const isKeyAccepted = keyboardEvent.key && ACCEPTED_KEYS.indexOf(keyboardEvent.key) > -1 && !keyboardEvent.ctrlKey;

        if (target?.tagName?.toLocaleLowerCase() === 'body'
            && isKeyAccepted
            && inputRef.current
        ) {
            inputRef.current.focus();
        }
    }, []);

    useEventListener('keydown', keydownIntoSearch);

    const updateSearchTerm = (term: string) => {
        dispatch<any>(setSearchTerm(term));
        setShowQuicksearch(true);
        if (term) {
            debouncedTrackAction(term, isSearchPage, trackAction);
        }
    };

    const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = event.target.value;
        updateSearchTerm(newSearchTerm);
    };

    const goToResults = (event: React.FormEvent) => {
        trackAction(FeatureTracked.OpenAdvancedSearch, { topic: TopicTracked.QuickSearch });
        const open = isSearchPage ? navigate : redirectExternalOpenNewUrl;
        open(`/search/${encodeURIComponent(searchTerm)
            .replace('%25', '%')
            .replace('%', '%25')}`);
        event.preventDefault();
    };

    const inputGroupClassName = {
        'input-group-md': ['md'].includes(mediaBreakpoint),
        'input-group-lg': ['lg', 'xl'].includes(mediaBreakpoint),
        'input-group-xl': ['xxl'].includes(mediaBreakpoint),
        'has-terms': searchTerm,
    };

    const formControlClassName = {
        'form-control-md': ['md'].includes(mediaBreakpoint),
        'form-control-lg': ['lg', 'xl', 'xxl'].includes(mediaBreakpoint),
    };

    return (
        <ActionTrackingInfoProvider
            actionTrackedInfo={{ topic: TopicTracked.QuickSearch }}
        >
            <form
                ref={rootElement}
                className={classNames('form-group w-100 search-bar', className)}
                onSubmit={goToResults}
            >
                <div className={classNames('input-group input-search', inputGroupClassName)}>
                    <div className="input-icon-start">
                        <em className="icon">search</em>
                    </div>
                    <div className="input-icon-end me-2">
                        <div className={classNames({ 'd-none': !searchTerm })}>
                            <button
                                className="btn btn-flat-primary"
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateSearchTerm('');
                                    inputRef?.current?.focus();
                                }}
                            >
                                <i className="icon icon-sm">close</i>
                            </button>
                        </div>
                        <div>
                            <button
                                className="btn btn-flat-primary"
                                type="submit"
                                disabled={!searchTerm}
                            >
                                {translate('service-list:search')}
                            </button>
                        </div>
                    </div>
                    <label htmlFor="search" className="screen-reader-text">search</label>
                    <input
                        id="search"
                        ref={inputRef}
                        type="search"
                        autoComplete="off"
                        role="searchbox"
                        className={classNames('form-control', formControlClassName)}
                        placeholder={translate(
                            canSearchPersonsAndCompanies
                                ? 'service-list:searchServicesPersonAndCompaniesPlaceHolder'
                                : 'service-list:searchServicesPlaceHolder',
                        )}
                        value={searchTerm}
                        onChange={onSearchTermChange}
                        onFocus={() => setShowQuicksearch(true)}
                    />
                </div>
                <Combobox
                    rootRef={rootElement}
                    isOpen={showQuicksearch && !isSearchPage}
                    onOutsideClick={() => setShowQuicksearch(false)}
                >
                    {searchTerm ? (
                        <QuicksearchResults openInNew={!isSearchPage} />
                    ) : (
                        <QuicksearchStartTyping onCategoryClick={() => setShowQuicksearch(false)} />
                    )}
                </Combobox>
            </form>
        </ActionTrackingInfoProvider>
    );
};
