import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getCountByProducers } from 'store/Notification/notificationThunk';
import { NotificationCountByProducerResponse, ProducerUniverse } from 'services/ApiService/Notification/NotificationApiClient';
import classNames from 'classnames';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { SkeletonWrapper } from 'components/common/SkeletonWrapper';
import Skeleton from 'react-loading-skeleton';
import { PRODUCER_CODE_ALL_KEY } from 'components/hooks/Notification/useNotificationListFilter';
import { useNotificationCountByProducer } from 'components/hooks/Notification/useNotificationCountByProducer';
import { ProducerCountItem } from './ProducerCountItem';
import { NotificationSettingsLink } from '../NotificationSettingsLink';
import { useNotificationTitle } from 'components/hooks/Notification/useNotificationTitle';

import './ProducerMenu.scss';

export const ProducerMenu: FC = () => {
    const { t: translate } = useTranslation('my-notification');
    const { countByProducers: { data, state }, allUnreadTotalCount } = useNotificationCountByProducer();
    const dispatch = useDispatch();
    const mediaBreakpoint = useMediaBreakpoint();
    const { title } = useNotificationTitle();
    const [menuIsCollapsed, setMenuIsCollapsed] = useState(true);

    useEffect(() => {
        if (state === 'neverFetched' || state === 'outdated') {
            dispatch<any>(getCountByProducers());
        }
    }, [state]);

    if (state === 'isFetching' || !data) {
        return <div className="producer-menu d-flex"><NotificationProducersSkeleton /></div>;
    }

    const dataWithProducer = data?.filter(d => !!d?.producer?.code) || [];
    if (dataWithProducer.length == 0) {
        return false;
    }

    const sgmUniverseCountByProducers = dataWithProducer.filter(c => c.producer!.universe === ProducerUniverse.SgMarkets) || [];
    const otherCountByProducers = dataWithProducer.filter(c => c.producer!.universe !== ProducerUniverse.SgMarkets) || [];

    const allLabelTranslated = translate('sortAll');
    const allProducerCountItem: NotificationCountByProducerResponse = {
        unreadCount: allUnreadTotalCount,
        producer: {
            code: PRODUCER_CODE_ALL_KEY,
            name: allLabelTranslated,
            internalOnly: true,
            universe: ProducerUniverse.SgMarkets,
        },
    };

    const isMobileView = ['xs', 'sm'].includes(mediaBreakpoint);
    const isTabletView = ['md'].includes(mediaBreakpoint);

    const wrapperClass = classNames('producer-menu-wrapper d-flex flex-md-column position-relative', {
        'fs-5 flex-nowrap': isMobileView,
        'bg-lvl2': !isMobileView,
        'shadow-xxl': !menuIsCollapsed && isTabletView,
    });

    const countMenuContentClass = classNames('bg-lvl2 menu-collapsable', {
        'menu-collapse': menuIsCollapsed,
        'order-last w-100': isMobileView,
    });

    const showSeparator = sgmUniverseCountByProducers.length > 0 && otherCountByProducers.length > 0;

    return (
        <div className="producer-menu d-flex">
            <div className={wrapperClass}>
                <button
                    id="count-menu-btn"
                    className="d-lg-none btn btn-flat px-0"
                    style={{ width: 48, height: 48 }}
                    onClick={() => setMenuIsCollapsed(!menuIsCollapsed)}
                    aria-expanded={menuIsCollapsed ? 'false' : 'true'}
                    aria-controls="count-menu-content"
                >
                    <i className="icon">menu</i>
                </button>
                <div className="d-md-none fw-medium ms-2 my-auto text-truncate h6">{title}</div>

                <div className={countMenuContentClass} id="count-menu-content">
                    <div className="fw-medium text-secondary mb-12px ms-4 mt-4">{translate('producerCountTitle')}</div>
                    <ul className="nav nav-xl nav-underline nav-vertical nav-hover overflow-y-auto producer-menu-list flex-nowrap">
                        <ProducerCountItem countByProducer={allProducerCountItem} />
                        {sgmUniverseCountByProducers.map(x => <ProducerCountItem key={x.producer?.code} countByProducer={x} />)}
                        {showSeparator && <hr className="m-0" />}
                        {otherCountByProducers.map(x => <ProducerCountItem key={x.producer?.code} countByProducer={x} />)}
                    </ul>
                </div>
                <div className="flex-grow-1" />
                <NotificationSettingsLink />
            </div >
        </div>
    );
};

const NotificationProducersSkeleton: FC = () => <>
    <div className="producer-menu-wrapper d-none d-lg-block p-3 bg-lvl2">
        <SkeletonWrapper>
            <Skeleton height={20} width={115} className="mb-1" />
            <Skeleton height={20} className="mb-1" count={3} />
            <Skeleton height={20} className="mb-1" />
            <hr />
            <Skeleton height={30} className="mb-1" count={2} />
        </SkeletonWrapper>
    </div>
    <div className="producer-menu-wrapper d-block d-lg-none p-2 bg-lvl2">
        <SkeletonWrapper>
            <Skeleton height={27} className="m-auto" />
        </SkeletonWrapper>
    </div>
</>;