import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-core';
import { configuration } from 'config/constants';

let sgwtConnect: SGWTConnectCore;

type AppWindow = {
    sgwtConnect: SGWTConnectCore;
    SGWTWidgetConfiguration: { environment: 'homologation' | 'production' };
};

export const loadSGConnect = () => {
    const sgConnectConfig = configuration.sgConnect;
    if (!sgwtConnect) {
        sgwtConnect = setupSGWTConnect({
            authorization_endpoint: sgConnectConfig.authorizationEndpoint,
            client_id: sgConnectConfig.clientId,
            scope: sgConnectConfig.scope,
            acr_values: sgConnectConfig.level,
            redirect_uri: sgConnectConfig.redirect_uri,
        });
    }

    setupPassiveMode();

    if (!sgwtConnect.isAuthorized()) {
        sgwtConnect.requestAuthorization();
        return;
    }
    const windowIfDefined =
        typeof window === 'undefined'
            ? null
            : ((window as unknown) as AppWindow);
    if (windowIfDefined && !windowIfDefined.sgwtConnect) {
        windowIfDefined.sgwtConnect = sgwtConnect;
    }

    return sgwtConnect;
};

export const setupPassiveMode = () => {
    const widget = document.querySelector('sgwt-connect') as any;
    if (!widget) {
        return;
    }

    if (typeof widget.setSgwtConnectInstance === 'undefined') {
        // retry until widget is loaded
        setTimeout(setupPassiveMode, 100);
        return;
    }

    widget.setSgwtConnectInstance(sgwtConnect);
};

export const getSGConnect = () => {
    return sgwtConnect;
};
