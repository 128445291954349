import React, { useEffect, useRef, useCallback } from 'react';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { FeatureTracked, TopicTracked } from 'models/tracking';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { useLocation } from 'react-router-dom';
import { COUNTRY_RESTRICTED_BROWSE_PATH } from 'models/constants';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-help-center': any;
            }
        }
    }
}

type HelpCenterRefElementFunc = (mountedElement: HTMLElement | null) => void;
export const HelpCenter: React.FC = () => {
    const { trackAction } = useServiceTracking();
    const { hasOnlyB2b2cAccess } = useApplicationContext();
    const { pathname } = useLocation();

    const setHelpCenterEventListeners = useRef<HelpCenterRefElementFunc>();
    const refPreviousEltMounted = useRef<HTMLElement | null>();

    const onOpenTracking = useCallback(async () => {
        await trackAction(FeatureTracked.OpenHelpCenter, {
            topic: TopicTracked.Help,
        });
    }, [trackAction]);

    let messageOnly = false;
    if (pathname.indexOf(COUNTRY_RESTRICTED_BROWSE_PATH) > -1) {
        messageOnly = true;
    }

    useEffect(() => {
        setHelpCenterEventListeners.current = (
            mountedElement: HTMLElement | null,
        ) => {
            if (mountedElement) {
                mountedElement.addEventListener(
                    'sgwt-help-center--open',
                    onOpenTracking,
                );
                // Some times mountedElement is different between re rendering, we keep last HTMLElement to remove removeEventListener
                refPreviousEltMounted.current = mountedElement;
            }
        };

        return () => {
            // Remove previous event handler
            if (refPreviousEltMounted.current) {
                refPreviousEltMounted.current.removeEventListener(
                    'sgwt-help-center--open',
                    onOpenTracking,
                );
            }
        };
    }, [onOpenTracking]);

    const application = hasOnlyB2b2cAccess
        ? 'service-board-b2b2c'
        : 'service-board';

    return <aside>
        <sgwt-help-center
            ref={setHelpCenterEventListeners.current}
            id="sgwtSuperHelpCenter"
            sg-connect-support="sg-connect-v2"
            application-id={application}
            introduction-tour={!hasOnlyB2b2cAccess}
            message-only={messageOnly}
        />
    </aside>;
};

export const openHelpCenterForm = (): void => {
    const helpCenter = getHelpCenter();
    if (helpCenter) {
        helpCenter.form();
    }
};

export const openHelpCenter = (): void => {
    const helpCenter = getHelpCenter();
    if (helpCenter) {
        helpCenter.open();
    }
};

export const openHelpCenterTopic = (topic: string): void => {
    const helpCenter = getHelpCenter();
    if (helpCenter) {
        helpCenter.topic(topic);
    }
};

const getHelpCenter = (): any => {
    return document.querySelector('sgwt-help-center') as any;
};