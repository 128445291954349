import React from 'react';
import { configuration } from 'config/constants';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-web-analytics': {
                    'site-id': string;
                    'base-url'?: string;
                };
            }
        }
    }
}

export const MatomoWidget: React.FC = () => {
    const {
        piwik: { siteId, url },
    } = configuration;

    return <sgwt-web-analytics site-id={siteId} base-url={url} />;
};
