import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NullableLink } from '../../layout/NoServices';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useActionTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { FeatureTracked } from 'models/tracking';
import { configuration } from 'config/constants';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';

const getIsSgGroup = (app: AppState): Nullable<boolean> =>
    app.applicationContext.context?.isSgGroupCurrentUser;

export const FormLink = () => {
    const { t: translate } = useTranslation('service-list');    
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();
    const { nonSgmLinkReferentialUrl } = configuration;
    const formUrl = nonSgmLinkReferentialUrl || null;
    const trackActionCallback = useCallback(async () => await trackAction(FeatureTracked.OpenForm, actionTrackedInfo), [actionTrackedInfo, trackAction]);
    const isSgGroup = useSelector(getIsSgGroup);

    if (!isSgGroup) {
        return null;
    }

    return (
        <div
            className="d-flex align-items-center mt-3 text-secondary"
        >
            <span>
                <i className="icon icon-sm">info_outlined</i>
            </span>
            <span>
                {translate('service-list:formLinkBefore')}
                <NullableLink
                   href={formUrl}
                   onLinkClick={trackActionCallback}
                >

                    {translate('service-list:formLinkInner')}
                </NullableLink>
                {translate('service-list:formLinkAfter')}
            </span>
        </div>
    );
};
